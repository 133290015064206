<template>
    <div class="text-start px-4 py-3 row h-100">
        <div class="col-12">
            <div class="row justify-content-center align-items-center">
                <div class="col-4">
                    <label for="person_name" class="person_labels">{{ $t("person.name") }}</label>
                </div>
                <div class="col-8">
                    <div class="input-group">
                        <input type="text" class="form-control" id="person_name"
                            aria-describedby="basic-addon3 basic-addon4" v-model="name">
                    </div>
                </div>
                <div class="col-8 offset-4">
                    <div class="form-text help_text" id="basic-addon4">{{ $t("person.nameHelp") }}</div>
                </div>
            </div>
            <div class="col-12 mt-4">
                <div class="row justify-content-center align-items-center">
                    <div class="col-4">
                        <label for="bidthdate" class="person_labels">{{ $t("person.birthdate") }}</label>
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <input type="date" class="form-control" id="bidthdate"
                                aria-describedby="basic-addon3 basic-addon4" v-model="birthdate" :max="maxDate"
                                min="1900-01-01">
                        </div>
                    </div>
                    <div class="col-8 offset-4">
                        <div class="form-text help_text" id="basic-addon4">{{ $t("person.birthdateHelp") }}</div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-4">
                            <label for="countries" class="person_labels">{{ $t("person.countries") }}</label>
                        </div>
                        <div class="col-8">
                            <div class="input-group">
                                <Multiselect v-bind="multiselectSettings" v-model="selectedCountries"
                                    class="multiselect_person_country">
                                    <template #option="optionProps">
                                        <span class="fi fi-big"
                                            :class="'fi-' + optionProps.option.iso.toLowerCase()"></span>
                                        <span class="ms-3">{{ optionProps.option.name }}</span>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>
                        <div class="col-8 offset-4">
                            <div class="form-text help_text" id="basic-addon4">{{ $t("person.countriesHelp") }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <!-- <p class="text-primary" v-if="disabledText">{{ $t("person.errors." + disabledText) }}</p> -->
                    <div class="d-flex align-items-center justify-content-center h-100">
                        <button class="btn btn-primary" :class="{ 'disabled': disabled }" @click="verify">{{
                            $t("person.verify")
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import axios from "axios";

const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    components: {
        Multiselect
    },
    data: function () {
        return {
            countries: [],
            selectedCountries: [],
            name: null,
            birthdate: null,
            maxDate: null
        }
    },
    created: function () {
        var language = document.getElementById("language").innerText;
        api.get("countries/" + language)
            .then(r => {
                this.countries = r.data.countries;
            })
        setInterval(function () {
            this.updateMaxDate();
        }.bind(this), 60000);
        this.updateMaxDate();
    },
    computed: {
        multiselectSettings: function () {
            return {
                label: "name",
                trackBy: "iso",
                placeholder: "",
                options: this.countries,
                searchable: true,
                internalSearch: true,
                showLabels: true,
                multiple: true,
                openDirection: "bottom",
                selectLabel: this.$t("utils.select"),
                selectedLabel: this.$t("utils.selected"),
                deselectLabel: this.$t("utils.deselect"),
                limitText: function (count) { return this.$tc("utils.more", count) }.bind(this),
                optionHeight: 60
            }
        },
        disabledText: function () {
            if (!this.name) {
                return "emptyName"
            }
            if (this.name.split(" ").length <= 1) {
                return "notFullName"
            }
            if (this.name.split(" ")[0].length <= 1) {
                return "shortFirstName"
            }
            if (this.name.split(" ")[1].length <= 1) {
                return "shortSecondName"
            }
            if (!this.birthdate) {
                return "emptyBirthdate"
            }
            if (this.selectedCountries == 0) {
                return "emptyCountries"
            }
            return ""
        },
        disabled: function () {
            return this.disabledText != ""
        }
    },
    methods: {
        updateMaxDate: function () {
            this.maxDate = new Date().toISOString().substring(0, 10);
        },
        verify: function () {
            if (this.disabled) {
                return
            }
            api.post("person/hash", {
                name: this.name,
                birthdate: this.birthdate,
                countries: this.selectedCountries
            })
                .then(r => {
                    if (r.data.hash) {
                        window.location.href = "/person/" + r.data.hash;
                    }
                })
        }
    }
}
</script>

<style></style>