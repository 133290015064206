<template>
    <div class="text-center">
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-10 col-lg-9 col-xl-7 text-center text-center">
                <h1 class="waiting-heading">{{ $t("international.waiting") }}</h1>
            </div>
        </div>

        <div class="row justify-content-center align-items-center mx-3 mx-md-5 bubbles-waiting">
            <div class="col-12 col-md-12 col-lg-11 col-xl-9 text-center">
                <div class="row flag flag_others justify-content-center align-items-center">
                    <div class="col-12">
                        <h2>{{ $t("international.heading") }}</h2>
                    </div>

                    <div class="col-auto loader_box">
                        <div class="pl1">
                            <div class="pl1__a"></div>
                            <div class="pl1__b"></div>
                            <div class="pl1__c"></div>
                        </div>
                        <span class="loader_claim">110M</span><br/>
                        <span class="loader_desc">{{ $t("international.companies") }}</span>
                    </div>

                    <div class="col-auto loader_box">
                            <div class="col-3 pl2">
                            <div class="pl2__a"></div>
                            <div class="pl2__b"></div>
                            <div class="pl2__c"></div>
                        </div>
                        <span class="loader_claim">576M</span><br/>
                        <span class="loader_desc">{{ $t("international.managers") }}</span>
                    </div>

                    <div class="col-auto loader_box">
                        <div class="col-3 pl3">
                            <div class="pl3__a"></div>
                            <div class="pl3__b"></div>
                            <div class="pl3__c"></div>
                            <div class="pl3__d"></div>
                        </div>
                        <span class="loader_claim">167M</span><br/>
                        <span class="loader_desc">{{ $t("international.owners") }}</span>
                    </div>

                    <div class="col-auto loader_box">
                        <div class="col-3 pl4">
                            <div class="pl4__a"></div>
                            <div class="pl4__b"></div>
                            <div class="pl4__c"></div>
                            <div class="pl4__d"></div>
                        </div>
                        <span class="loader_claim">295M</span><br/>
                        <span class="loader_desc">{{ $t("international.shareholders") }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

const api = axios.create({
    baseURL: "/api",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

export default {
    data() {
        return {
            bvd_id: null,
            elapsedTime: 0
        }
    },
    mounted: function () {
        this.$i18n.locale = document.getElementById("language").innerText;
        this.bvd_id = document.getElementById("bvd_id").innerText;
        setInterval(function () { this.elapsedTime++ }.bind(this), 1000);
        this.openSubject();
    },
    methods: {
        openSubject: function () {
            api.get("/international/open/" + this.bvd_id)
                .then(function () {
                    window.location.replace("/subject/opened/" + this.bvd_id);
                }.bind(this))
                .catch(e => {
                    if (axios.isCancel(e)) {
                        console.log("Request canceled", e.message);
                    } else {
                        if (e.response.status >= 400) {
                            alert(this.$t("international.errors." + e.response.data.error_code));
                        }
                        else {
                            console.log(e);
                        }
                    }
                });
        },
    }
}
</script>

<style scoped>
</style>
